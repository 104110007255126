// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebote-des-hauses-js": () => import("./../../../src/pages/angebote-des-hauses.js" /* webpackChunkName: "component---src-pages-angebote-des-hauses-js" */),
  "component---src-pages-buchhandlung-js": () => import("./../../../src/pages/buchhandlung.js" /* webpackChunkName: "component---src-pages-buchhandlung-js" */),
  "component---src-pages-das-haus-js": () => import("./../../../src/pages/das-haus.js" /* webpackChunkName: "component---src-pages-das-haus-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-datenschutz-js": () => import("./../../../src/pages/en/datenschutz.js" /* webpackChunkName: "component---src-pages-en-datenschutz-js" */),
  "component---src-pages-en-events-js": () => import("./../../../src/pages/en/events.js" /* webpackChunkName: "component---src-pages-en-events-js" */),
  "component---src-pages-en-gallery-js": () => import("./../../../src/pages/en/gallery.js" /* webpackChunkName: "component---src-pages-en-gallery-js" */),
  "component---src-pages-en-impressum-js": () => import("./../../../src/pages/en/impressum.js" /* webpackChunkName: "component---src-pages-en-impressum-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-literature-award-js": () => import("./../../../src/pages/en/literature-award.js" /* webpackChunkName: "component---src-pages-en-literature-award-js" */),
  "component---src-pages-en-offerings-of-the-house-js": () => import("./../../../src/pages/en/offerings-of-the-house.js" /* webpackChunkName: "component---src-pages-en-offerings-of-the-house-js" */),
  "component---src-pages-en-the-house-js": () => import("./../../../src/pages/en/the-house.js" /* webpackChunkName: "component---src-pages-en-the-house-js" */),
  "component---src-pages-forschung-js": () => import("./../../../src/pages/forschung.js" /* webpackChunkName: "component---src-pages-forschung-js" */),
  "component---src-pages-freundeskreis-js": () => import("./../../../src/pages/freundeskreis.js" /* webpackChunkName: "component---src-pages-freundeskreis-js" */),
  "component---src-pages-fundraising-js": () => import("./../../../src/pages/fundraising.js" /* webpackChunkName: "component---src-pages-fundraising-js" */),
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-literaturwettbewerb-js": () => import("./../../../src/pages/literaturwettbewerb.js" /* webpackChunkName: "component---src-pages-literaturwettbewerb-js" */),
  "component---src-pages-veranstaltungen-js": () => import("./../../../src/pages/veranstaltungen.js" /* webpackChunkName: "component---src-pages-veranstaltungen-js" */)
}

