import { theme } from "@chakra-ui/core"
import React, { Fragment } from "react"
// export default theme

export default {
  breakpoints: ["48em", "75em"],
  fonts: {
    heading: '"Traulha Jornau", sans-serif',
    body: '"Traulha Jornau", sans-serif',
    mono: '"Traulha Jornau", sans-serif',
  },
  colors: {
    ...theme.colors,
    yellow: "#f9f8f1",
    red: "#e75146",
    white: "#ffffff",
    green: "#007c60",
    brown: "#c4bb75",
    black: "#000000",
    blue: "#283582",
    greenLight: "#E5F2EF",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "4-5xl": "2.7rem",
    "5xl": "3rem",
    "5-5xl": "3.5rem",
    "6xl": "4rem",
  },
  lineHeights: {
    normal: "normal",
    none: "1",
    shorter: "1.25",
    short: "1.375",
    base: "1.5",
    tall: "1.625",
    taller: "2",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  icons: {
    ...theme.icons,
    read: {
      path: (
        <g transform="translate(0 0.75)">
          <line
            x2="31.5"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5px"
          />
          <line
            x2="35"
            transform="translate(0 7)"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5px"
          />
          <line
            x2="32.5"
            transform="translate(0 14)"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5px"
          />
          <line
            x2="35"
            transform="translate(0 21)"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5px"
          />
          <line
            x2="32.5"
            transform="translate(0 28)"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5px"
          />
          <line
            x2="34"
            transform="translate(0 35)"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5px"
          />
        </g>
      ),
      viewBox: "0 0 36.5 36.5",
    },
    cross: {
      path: (
        <g transform="translate(1.104 0.652)">
          <path
            d="M0,30.225,30.69,0"
            transform="translate(1 1)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="3"
          />
          <path
            d="M.31.762,31,32"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="3"
          />
        </g>
      ),
      viewBox: "0 0 34.208 34.066",
    },
    gallery: {
      path: (
        <Fragment>
          <g>
            <rect
              x="1.75"
              y="1.75"
              width="81.7"
              height="81.7"
              fill="none"
              stroke="currentColor"
              strokeWidth="3.5px"
            />
            <rect
              x="11.05"
              y="20.45"
              width="63"
              height="42"
              fill="none"
              stroke="currentColor"
              strokeWidth="3.5px"
            />
            <path
              d="M11.05,43.85l9.7,-9l9.2,9l5.4,-5.4l16.3,16.3l10.9,-11l11,11"
              fill="none"
              fillRule="nonzero"
              stroke="currentColor"
              strokeWidth="3.5px"
            />
          </g>
          <circle
            cx="58.4"
            cy="32.2"
            r="5.3"
            fill="none"
            stroke="currentColor"
            strokeWidth="3.5px"
            strokeMiterlimit="10"
          />
        </Fragment>
      ),
      viewBox: "0 0 86 86",
    },
    menu: {
      path: (
        <g
          id="Group_12"
          data-name="Group 12"
          transform="translate(-7.75 -4.53)"
        >
          <path
            id="Line_Copy_2"
            data-name="Line Copy 2"
            d="M0,0H27"
            transform="translate(8.5 5.5) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line"
            d="M0,0H27.44"
            transform="translate(16 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line_Copy"
            data-name="Line Copy"
            d="M0,0H27.44"
            transform="translate(23 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line_Copy_2-2"
            data-name="Line Copy 2"
            d="M0,0H27"
            transform="translate(29.5 5.5) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line-2"
            data-name="Line"
            d="M0,0H27.44"
            transform="translate(37 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line_Copy-2"
            data-name="Line Copy"
            d="M0,0H27.44"
            transform="translate(44 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line_Copy_3"
            data-name="Line Copy 3"
            d="M0,0H27.44"
            transform="translate(51 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line_Copy_4"
            data-name="Line Copy 4"
            d="M0,0H27.44"
            transform="translate(58 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line_Copy_5"
            data-name="Line Copy 5"
            d="M0,0H27.44"
            transform="translate(65 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line_Copy_6"
            data-name="Line Copy 6"
            d="M0,0H27.44"
            transform="translate(72 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line_Copy_7"
            data-name="Line Copy 7"
            d="M0,0H27.44"
            transform="translate(79 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line_Copy_8"
            data-name="Line Copy 8"
            d="M0,0H27.44"
            transform="translate(86 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Line_Copy_9"
            data-name="Line Copy 9"
            d="M0,0H27.44"
            transform="translate(93 5.28) rotate(90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
        </g>
      ),
      viewBox: "0 0 86 28.94",
    },
    logo: {
      path: (
        <g transform="translate(-63 -350.936)">
          <path
            d="M160.9,408.895l-97.484-.013.022-.409,13.656-6.346,36.127-20.985L147.5,402.127l13.391,6.347,0,.421Zm97.605,10.29h19.04m-9.673,19.743V419.193M65.717,408.917v36.9H310.6V411.882m-51.99,33.829V412.248m18.952,26.78V412.422h-44.4m77.395,26.584H65.717m74.408-.094V414.964H157.19m0,18.448V411.994m-16.911,21.418h92.856m0,5.59V398.36l9.8-16.6h58.454l12.145,17.7v3.823h-2.95m-77.4,27.864h25.323M245.9,431.05V412.275m-12.914-9.149h-2.767V398.36L241.9,378.836m-2.121,0h64.686l0-.375-11.834-8-20.481-18.987-20.72,18.987-11.644,8-.009.375Zm38.446-9.756a7.524,7.524,0,0,0-6-3.088,7.745,7.745,0,0,0-6.106,3.088,20.405,20.405,0,0,1-2.251,2.223h16.609a20.407,20.407,0,0,1-2.251-2.223Zm37.253,30.551h-1.868m-188.242-.309A15.127,15.127,0,0,0,113,393.437a16.1,16.1,0,0,0-12.584,5.885,29.3,29.3,0,0,1-4.6,4.236h34.125a26.251,26.251,0,0,1-4.569-4.236Zm17.585.022H230.1m-72.839,14.262h75.815m-167.36-1.644h93.062v-2.941m77.507-16.075v14.281m3.115-19.412v17.516m3.183-22.943v21.047m3.045-21.609v21.66m3.113-21.664v21.613m3.114-21.617v23.615m3.115-23.618v25.562m3.114-25.564v27.619m2.226-9.869v7.1m4.7-7.1v7.1M261.2,381.807v17.631m3.115-17.632v17.632M261.2,411.462v-2.748m3.115,3.8v-3.8m3.114-26.913v30.521m3.114-30.523v30.475m3.114-30.479v30.527m3.114-30.529v30.481m3.114-30.487v17.625M283,381.785v17.627m-3.115,13.077v-3.75M283,412.513v-3.774m3.114-26.957v30.782m3.114-30.787v30.787m3.114-30.789v30.789m3.114-30.792v30.792m3.115-30.795v30.795m3.044-30.4v30.4m3.009-26.04v26.04m2.941-21.8v21.8m3.048-17.421v17.491m-55.647-13.12h34.206m-31.054,7.149h9.147m-9.147,2.041h9.147m11.819-9.141v7.1m4.7-7.1v7.1m-6.859,0h9.147m-9.147,2.041h9.147M278.9,439.1v2.052m-20.344.041H283.2v2.288m-24.495-.174h28.338v2.521m-9.4-33.411H310.6m-77.418-3.244,9.326-5.739h6.3l13.821,8.983m-29.526-1.871-74.313.127m-5.666,4.343v18.354"
            fill="none"
            stroke="#010000"
            strokeWidth="0.788px"
          />
          <path
            d="M302.527,419.423h-3.6v-4.379h3.6v4.379Zm.019,12.206h-3.619v-10.9h3.619v10.9ZM275.5,414.671v2.7H260.366v-2.7Zm-9.567,16.958H260.4V421.05h5.535v10.579Zm23.754-12.206h-3.6v-4.379h3.6v4.379Zm.019,12.206h-3.618v-10.9h3.618v10.9Zm7.844-12.206h-6.485v-4.379h6.485v4.379Zm.035,12.206h-6.52v-10.9h6.52v10.9Zm-22.259,0h-5.535V421.05h5.535v10.579Zm-3.16-63.862a4.026,4.026,0,0,0-3.549,1.953h7.1a4.025,4.025,0,0,0-3.548-1.953Zm-14.13,31.747h-3.074V386.831h3.074v12.683Zm6.227,0h-3.075V386.831h3.075v12.683Zm6.226,0h-3.075V386.831h3.075v12.683Zm6.226,0h-3.074V386.831h3.074v12.683Zm6.3,0h-3.074V386.831h3.074v12.683Zm6.15,0H286.1V386.831h3.075v12.683Zm-24.253,7.1h-4.584V401.43h4.584v5.182Zm18.816,0H279.15V401.43h4.584v5.182Zm-176.1-9.716v4.616h4.476v-5.678a13.081,13.081,0,0,0-4.476,1.062Zm-5.279,4.616h3.934v-3.924a10.873,10.873,0,0,0-3.934,3.924Zm11.1-5.7v5.7h4.74v-4.637a13.131,13.131,0,0,0-4.74-1.06Zm10.064,5.7a10.9,10.9,0,0,0-3.979-3.953v3.953Zm120.018,13.211v3.858h-7.65v-3.858Zm0,10.2v3.857h-7.65v-3.857Zm0-5.1v3.857h-7.65v-3.857Zm12.359-5.1v3.858h-7.65v-3.858Zm0,10.2v3.857h-7.65v-3.857Zm0-5.1v3.857h-7.65v-3.857ZM106.81,431.1h-5.877V416.819h5.877V431.1Zm28.844,0h-5.877V416.819h5.877V431.1Zm-7.211,0h-5.877V416.819h5.877V431.1Zm-7.211,0h-5.877V416.819h5.877V431.1Zm-7.211,0h-5.877V416.819h5.877V431.1Zm67.85-4.911h-7.156v-8.3h7.156v8.3Zm8.443,0h-7.156v-8.3h7.156v8.3Zm8.443,0H191.6v-8.3h7.156v8.3Zm8.442,0h-7.156v-8.3H207.2v8.3Zm8.443,0h-7.156v-8.3h7.156v8.3ZM302.453,378.8l14.679,20.867-2.841-.008M300.9,378.8"
            fill="none"
            stroke="#010000"
            strokeWidth="0.788px"
            strokeMiterlimit="10"
          />
        </g>
      ),
      viewBox: "0 0 254.891 95.271",
    },
    backgroundHouse: {
      path: (
        <g transform="translate(-618.869 -338)">
          <path
            d="M.338,2.015H172.961M86.1,182V2.015"
            transform="translate(853 678)"
            fill="none"
            stroke="#007c60"
            strokeWidth="0.5px"
          />
          <path
            d="M1.5,262V0"
            transform="translate(852 615)"
            fill="none"
            stroke="#007c60"
            strokeWidth="0.5px"
          />
          <path
            d="M403,246V0H0"
            transform="translate(623 615)"
            fill="none"
            stroke="#007c60"
            strokeWidth="0.5px"
          />
          <path
            d="M0,173.052H232m-115.549,0V0"
            transform="translate(620 615)"
            fill="none"
            stroke="#007c60"
            strokeWidth="0.5px"
          />
          <path
            d="M1.946,55.319V213.25M31.114,5.668V196.92M59.013.521v196.4M87.53.482V198.05M116.067.451v216.09M144.6.42V235.5M173.13.4V254m20.394-92v65.5M236.584,162v65.5M201.667.35V162M230.2.334V162M201.667,272.6V245.306M230.2,277V245.306M258.733.292V277M287.263.272V277M315.8.233V277M344.329.218V277M372.858.163V162.25M401.4.144V162.25M372.858,276.8V246.788M401.4,276.81V246.788M429.925.117V277M458.458.078V277M486.983.054V277M515.517.023V277M544.054,0V277"
            transform="translate(676 338)"
            fill="none"
            stroke="#007c60"
            strokeWidth="0.5px"
          />
          <path
            d="M4.356,1.949l313.85.44M33.13,67.536h85.412M221.253,2.389V67.536m43.113-65.15v65.15m-59.992,0h85.5M204.374,86.3l85.5-.046M209.436,117H508"
            transform="translate(816 498)"
            fill="none"
            stroke="#007c60"
            strokeWidth="0.5px"
          />
          <path
            d="M0,52.434,85.4,0h57.716L265.13,79.99"
            transform="translate(619 535)"
            fill="none"
            stroke="#007c60"
            strokeWidth="0.5px"
          />
          <path
            d="M-13545.928-5542.731h-85.262"
            transform="translate(14480.319 6126)"
            fill="none"
            stroke="#007c60"
            strokeWidth="0.5px"
          />
        </g>
      ),
      viewBox: "0 0 705.131 539",
    },
    zurueck: {
      path: (
        <g
          transform="translate(43 21) rotate(180)"
          fill="currentColor"
          strokeMiterlimit="10"
        >
          <path
            d="M 32.53037261962891 20.29186820983887 L 31.35208702087402 19.11016845703125 L 38.13525009155273 12.33940982818604 L 38.99068832397461 11.48552989959717 L 37.78202056884766 11.48552989959717 L 0.5 11.48552989959717 L 0.5 9.514470100402832 L 37.78202056884766 9.514470100402832 L 38.98666000366211 9.514470100402832 L 38.13608932495117 8.661430358886719 L 31.35444450378418 1.860105514526367 L 32.52720260620117 0.7049517035484314 L 42.29391098022461 10.5 L 32.53037261962891 20.29186820983887 Z"
            stroke="none"
          />
          <path
            d="M 32.53037643432617 19.58373641967773 L 41.58783340454102 10.5 L 32.52402877807617 1.409893393516541 L 32.0637092590332 1.863300919532776 L 38.49015045166016 8.308389663696289 L 40.19129943847656 10.01447010040283 L 37.78202056884766 10.01447010040283 L 1 10.01447010040283 L 1 10.98552989959717 L 37.78202056884766 10.98552989959717 L 40.19935989379883 10.98552989959717 L 38.48847961425781 12.69328022003174 L 32.05900573730469 19.11099815368652 L 32.53037643432617 19.58373641967773 M 32.53038024902344 21 L 30.64517021179199 19.10932922363281 L 37.78202056884766 11.98552989959717 L 0 11.98552989959717 L 0 9.014470100402832 L 37.78202056884766 9.014470100402832 L 30.64517021179199 1.856909990310669 L 32.53038024902344 0 L 43 10.5 L 32.53038024902344 21 Z"
            stroke="none"
          />
        </g>
      ),
      viewBox: "0 0 43 21",
    },
    play: {
      path: (
        <polygon fill="none" stroke="#e75146" strokeMiterlimit="10" strokeWidth="3.15px" points="1.9 42.7 1.9 3.72 35.65 23.21 69.41 42.7 35.65 62.19 1.9 81.67 1.9 42.7"/>
      ),
      viewBox: "0 0 73.17 85.2"
    }
  },
}
